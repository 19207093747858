var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Good to know")]),
      _c(
        "gov-table",
        [
          _c(
            "template",
            { slot: "body" },
            [
              _vm._l(_vm.usefulInfos, function(usefulInfo) {
                return _c(
                  "gov-table-row",
                  { key: usefulInfo.order },
                  [
                    _c(
                      "gov-table-header",
                      { attrs: { scope: "row", top: "" } },
                      [_vm._v(_vm._s(usefulInfo.title))]
                    ),
                    _c("gov-table-cell", {
                      domProps: {
                        innerHTML: _vm._s(_vm.toHtml(usefulInfo.description))
                      }
                    })
                  ],
                  1
                )
              }),
              _vm.usefulInfos.length === 0
                ? _c(
                    "gov-table-row",
                    [
                      _c("gov-table-cell", { attrs: { colspan: "2" } }, [
                        _vm._v(
                          "No useful info specified for this\n          " +
                            _vm._s(_vm.service.type)
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }